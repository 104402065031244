/* .newHighScore{
    width: 100%;
    position: relative;
    z-index: 9999;
} */

.newHighScore-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #d4f0f99a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.newHighScore-hold {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 30px;
}

.newHighScore-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background-color: white;
  max-width: 356px;
  width: 100%;
  max-height: 500px;
  height: 100%;
  padding: 15px 20px;
  border-radius: 16px;
}

.newHighScore-title {
  color: black;
  font-size: 25px;
  font-weight: bold;
}

.newHighScore-imgWrap {
  max-width: 120px;
  width: 100%;
  background-color: white;
  border-radius: 50%;
  padding: 20px;
  border: 3px solid #d92b58;
}

.newHighScore-imgWrap img {
  width: 100%;
}

.newHighScore-wrap .button {
  padding: 10px 50px;
  cursor: pointer;
  background-color: #d92b58;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  border-radius: 50px;
}

.newHighScore-loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newHighScore-loaderWrap .loader {
  width: 18px;
  height: 18px;
  border: 5px solid;
  border-color: #ff3d00 transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 0 auto;
}

@media (max-width: 429px) {
  .newHighScore-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background-color: white;
    max-width: 400px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    padding: 15px 20px;
    border-radius: 10px;
    border: 2px solid #c4ecf9;
  }

  .newHighScore-imgWrap {
    width: 60px;
    background-color: white;
    padding: 20px;
  }
}
