.layout {
  display: flex;
}

.body {
  flex: 6;
  background-color: white;
  background: linear-gradient("transparent", var(--green-color));
  padding: 50px 30px;
  box-sizing: border-box;
  overflow: hidden;
}
.project {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.label {
  margin-right: 5px;
}
.select {
  width: 100%;
  max-width: 200px;
  height: 40px;
}
