.sidebar {
  display: none;
}
.link {
  color: white;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .childrenContent {
    /* padding-top: 130px; */
  }
}
@media screen and (min-width: 768px) {
  .container {
    display: flex;
    /* justify-content: center; */
    width: 100%;
  }
  .sidebar {
    display: block;
    min-height: 100vh;
    background-color: #f8f8f8;
    color: #7f7f7f;
    flex-basis: 345px;
    flex: 0.3;
    min-width: 300px;
    max-width: 300px;
  }
  .childrenContainer {
    max-width: calc(100vw - 300px);
  }

  .childrenContent {
    display: flex;
    justify-content: center;
  }
  .content {
    width: 100%;
    max-width: 745px;
  }
  .navFlex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .activeNavLink {
    color: #61a641 !important;
  }
  .activeNav {
    background-color: #e3ffd6;
  }

  .idleNavLink {
    color: #7f7f7f;
  }
}
