.paymentCtn{
    width: 100%;
    height: auto;
    margin: 0 auto;
}
.paymentHeading{
    font-size: 1.4rem;
    text-align: center;
    color: var(--grey-color);
    font-weight: 900;
    margin: 0;
    margin-top: 1rem;
    /* font-family: Poppins; */
}
.paymentImgCtn{
    width: 12.5rem;
    height: 10rem;
    margin: 0.6rem auto;
}
.secureDlinkImgCtn{

    width: 12.5rem;
    height: 17rem;
    margin: 0.6rem auto;

}
.processingImgCtn{
    width: 22.1rem;
    margin: 0.7rem auto;
    height: 10.6rem;
}
.paymentImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.secureLinkImg{
    width: 100%;
}
.paymentType,
.paymentAmt,
.subscriptopnActivityCtn,
.secureDlinkList{
    background-color: var(--white-color);
    max-width: 100%;
    /* height:23rem; */
    border-radius: 1.5rem 1.5rem 0 0;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.planBtn{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.paymentType :not(:last-child),
.paymentAmt :not(:last-child),
.subscriptopnActivityCtn :not(:last-child),
.secureDlinkList{
    margin-bottom: 1rem;
}

.AvailablePln,
.subscriptionAct{
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.AvailablePln :not(:last-child),
.subscriptionAct :not(:last-child){
    margin-right: 2.5rem;
}
.subscriptionAct{
    margin:1.3rem 0 1.4rem 0;

}
.AvailablePln{
    margin-top: 2rem;
}
.paymentAmt,
.subscriptopnActivityCtn{
    margin-top: 1.4rem;
    padding: 1.5rem 1rem 1rem 0.8rem;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}
.paymentType{
    margin-top: 1.2rem;
    padding: 1rem 0.8rem 1rem 0.8rem;
}
.pendingCtn{
    margin-top: 1.2rem ;
    color: var(--grey-color);
    padding:0rem 0.6rem 0.6rem 0.6rem ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.pendingheading{
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 0.7rem;
}
.pendingText{
    font-size: 1rem;
    font-weight: 900;
    width: 18rem;  
    text-align: center;
    line-height: 2rem;
 
}
.subscriptionHistory{
    padding: 0 2rem;
}
.historyheading{
    display: flex;
    justify-self: flex-start;
    font-size: 0.9rem;
    color: var(--grey-color);
    font-weight: 600;
}
.historyList{
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0 0.8rem 0.8rem 0;
}
.PaymentInfo{
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconContanner{
    height: 1.9rem;
    width:1.9rem;
    background-color: #fff;
    border-radius: 0.5rem;
    color: var(--green-color);
    margin-right: 0.9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  

}
.topUp-section{
 width: 19rem;
 display: flex;
justify-self: flex-start;
}
.topUp-heading{
  font-size: 0.9rem;
  font-weight: 600;
  color: var( --light-black);
 display: flex;
 flex-direction: column;
justify-self: flex-start;

}
.topUpSpan{
    display: block;
    color: var(--grey-color);
    font-size: 0.75rem;
    margin:0.4rem 0 0 0;
}
.amount{
    color: var(--green-color);
    font-weight: 600;
    font-size: 0.9rem;
    width: 1rem;
    
}
.topUpStatus{
    color: var(--green-color);
    font-size: 0.75rem;
    margin:-1rem 0 0 0 ;
    display: block;

}

.pendingLink{
    text-decoration: none;
    color: var(--white-color);
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    color: var(--green-color);
    text-transform: capitalize;
    border-radius: 0.5rem;
    /* background-color: var( --green-color); */
    color: var(--white-color);
    /* padding:0.3rem 1.5rem; */
   
}
.PendingLinkCtn{
    width: 18.5rem;
    height: 40px;
    text-align: center;
    border-radius: 0.8rem;
    background-color: var(--green-color);
  
}
.linkCtn{
    margin-top: 2rem;
    margin-left: 1rem;
}
.paymentMtd{
    font-size: 0.9rem;
    font-weight: 900;
    color: var(--grey-color);
}
.Leaderboard-loaderWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
   
  }
  .subloadingstate{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    /* display: block; */
    /* margin:15px auto; */
    margin-left: 55px;
    position: relative;
    color: var(--green-color);
    /* box-sizing: border-box; */
    animation: animloader 2s linear infinite;
}
.subLoadingCtn{
    display: flex;
    justify-content: center;
    align-items: center;

}
.setPinForm,
.addAccSetPinForm{
    margin-bottom: 2.5rem;
    max-width: 30px;
    width: 100%;
    height: 40px;
    font-size: 25px;
    color: #5875B5;
    font-weight: 600;
    text-align: center;
}
.addAccSetPinForm{
    margin-right: 40px;
    margin-top: 10px;
}
.setPinForm{
    margin-right: 10px;

}
.pinSettingsheading{
    font-size: 1.1rem;
    color: var(--grey-color);
    font-weight: 700;
    text-align: center;
}
.winningInput{
    width: 100%;
    display: block;
    height: 2.5rem;
    border-radius:0.5rem ;
    background-color: var(--grey-2);
    border: none;
    outline: none;
    /* padding: 0 1.5rem; */
    margin-top: 0.6rem;
}
.winningInput::placeholder{
    padding: 1rem;
    
}
.winningsection{
    background-color: var(--white-color);
    max-width: 100%;
    /* height:23rem; */
    border-radius: 1.5rem 1.5rem 0 0;
    padding: 2.8rem 1rem 1.8rem 1rem;

   

}
.winnningLabel{
    font-size: 0.87rem;
    font-weight: 700;
    color: var(--grey-color);
}
.winningInputctn{
    margin-bottom: 32px;
    
}
.winnningAccLabel{
    display: flex;

}
.AccPinSection{
    text-align: center;
}
.ViewAccountCtn{
    width: 100%;
    border: 1px solid var(--green-color);
    height: 6rem;
    border-radius: 0.9rem;
    padding:0.8rem 0.4rem;
    margin-bottom: 1rem;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
}
.viewEditCtn{
    width: 100%;
     display: flex;
    justify-content: space-between; 
    /* align-items: center; */
    /* flex-direction: column; */
    
}
/* .vieweditIcon{
    display: flex;
    justify-content: flex-end;
} */
.viewEditIconCtn{
    margin-left: 0.5rem;
}
.BankOwnersName{
    font-weight: 800;
    font-size: 1rem;
    color: var(--grey-color);
    margin-bottom: 0.6rem;
}
.BankDetails{
    font-weight: 700;
    font-size: 0.8rem;
    color: var(--grey-color);
}
.viewAccountCtns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--white-color);
    padding:2rem 0.8rem 0.8rem 0.8rem;
    border-radius: 1.5rem 1.5rem 0 0;
    height: 100vh;
   

}
.viewAcctBtn{
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
   
}
.viewAccountSecurityModal,
.successModal{
    width: 100%;
   
    background-color: var(--white-color);
    border-radius: 1rem;
    display: flex;
    justify-content: center; 
    align-items: center; 
     flex-direction: column;
}
.viewAccountSecurityModal{
    height: 19.9rem;
}
.successModal{
    height: auto;
    padding: 14px 8px;
  
}
.imgStyle{
    width: 189px;
    height: 274px;
    object-fit: contain;
}
.modalBtnCtn{
    width: 90%;
    justify-content: flex-end;

}
.viewAccText{
    color: var(--grey-color);
    margin-bottom: 1.5rem;
    font-size: 20px;
    text-align: center;
}
.paymentBtnctn{
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-top: 8px;
    color: var(--light-black);
}
.subscriptionInfo{
    color: var(--grey-color);
    text-align: center;
    font-size: 15px;
    margin-top: 10px;

}
.secureDlink{
   text-decoration: none;
   color: var(--white-color);
    width: 18.5rem;
    height: 40px;
    background-color: var(--green-color);
    border-radius: 0.8rem;
    border: 1px solid var(--green-color);
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;

}


@media screen and (min-width: 600px) {
    
    .subscriptopnActivityCtn{
    justify-content: space-between;
    align-items:stretch;
    }
    .paymentType{
        justify-content: center;
    }
  }
  @media screen and (min-width: 300px) {

    .addAccSetPinForm{
        margin-right: 20px;
        
    }
  }





 
