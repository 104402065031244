/* @import url("https://fonts.cdnfonts.com/css/avenir-lt-std?styles=15286,15283,15279,15275"); */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Pacifico";
  src: url("https://fonts.cdnfonts.com/css/avenir-lt-std?styles=15286,15283,15279,15275")
    format("woff2");
  /* Other font formats and sources can be added here */
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir LT Std", sans-serif;
}

:root {
  --red-color: #f24444;
  --green-color: #61a641;
  /* --green-color: #f29f06; */
  --white-color: #f8f8f8;
  --grey-color: #707070;
  --yellow-color: #f29f06;
  --orange-color: #f07404;
  --light-green: #dfedd9;
  --light-black: #333333;
  --font-family: "Avenir LT Std", sans-serif;
  --grey-2: #f1f1f1;
  --grey-3: #d3d3d3;
  --pink: #d92b58;

  /* --bg-color: #fffaf5;
  --bold-text-color: #131626;
  --light-text-color: #777a8a;
  --text-family: "Outfit", sans-serif;
  --text-family-2: "Bai Jamjuree", sans-serif;
  --border-color:#DBDEEE;
  --border-color--2:#D9D9D9;
  --border-color--3:#667261;
  --orange-color:#F8A08C;
  --grey-color:#98A2B3; */
}

.whiteContainer {
  background-color: var(--white-color);
  margin-top: 2rem;
  border-radius: 40px 40px 0px 0px;
  min-height: 56vh;
  padding: 1rem 1.5rem 7rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.firstColumn {
  color: var(--green-color);
  font-size: 14px;
  font-weight: bold;
}

.action {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.editIcon {
  cursor: pointer;
  font-size: 18px;
  color: var(--green-color);
}

.deleteIcon {
  cursor: pointer;
  font-size: 18px;
  color: var(--red-color);
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
