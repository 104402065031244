.nav {
  padding: 10px 50px;
  background-color: #333333;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logoWrap img {
  max-width: 80px;
  width: 100%;
}

.text {
  color: var(--white-color);
  font-weight: 600;
}
.projects {
  display: flex;
  align-items: center;
  gap: 20px;
}
.project {
  display: flex;
  align-items: center;
  width: max-content;
  color: white;
  /* margin-bottom: 20px; */
}
.label {
  margin-right: 5px;
}
.select {
  width: 100%;
  width: max-content;
  /* max-width: 200px; */
  height: 40px;
}
.select option {
  background-color: white;
}
