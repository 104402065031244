/* .answerCorrect{
    width: 100%;
    position: relative;
    z-index: 9999;
} */

.answerCorrect-container{
    position:fixed;
    width: 100%;
    height: 100%;
    background-color: #d4f0f99a;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.answerCorrect-hold{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px 30px;
}

.answerCorrect-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: white;
    max-width: 400px;
    width: 100%;
   height: 330px;
    /* height: 100%; */
    padding: 15px 20px;
    border-radius: 100px;
    border: 2px solid #C4ECF9;

}

.answerCorrect-textsWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.answerCorrect-title{
    color: black;
    font-size: 25px;
    font-weight: bold;
}

.answerCorrect-imgWrap{
    max-width: 120px;
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 100px;
}

.answerCorrect-imgWrap img{
    width: 100%;
}

.answerCorrect-wrap button{
    padding: 10px 50px;
    cursor: pointer;
    background-color: #D92B58;
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 15px;
}




@media(max-width: 429px){
    .answerCorrect-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: white;
        max-width: 400px;
        width: 100%;
        max-height: 500px;
        height: 100%;
        padding: 15px 20px;
        border-radius: 10px;
        border: 2px solid #C4ECF9;
    
    }

    .answerCorrect-imgWrap{
        width: 70px;
        background-color: white;
        padding: 20px;
    }
}